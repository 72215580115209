<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="12" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', 'groupLists', 'groupLists')"
                  ><i class="fas fa-file-pdf"></i></CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>

                </CButtonGroup>
                <BeforeFilter :filterShow="toggleFilterValue" @getFilterToggle="switchFilter"></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>

          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol sm="6">
                <div class="form-group">
                  <label class="form__label">Groups</label>
                  <v-select :options="groupList" v-model="groups.groupList"></v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      type="submit"
                      size="md"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton> &nbsp;
                  <CButton color="danger" size="md" @click="resetFilter()"
                           v-c-tooltip="$lang.common.filter.button.cancel">
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>

          <v-server-table
              :columns="columns"
              :url="listUrl"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', 'groupLists', 'groupLists')"
          >
            <template #actions="data">
              <CButton
                  color="primary"
                  v-on:click="viewUsers(data.row.groupId,data.row.title)"
                  v-c-tooltip="$lang.buttons.general.crud.view_group_users"
              >
                <i class="fas fa-user"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import BeforeFilter from "../BeforeFilterOpen";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueForm, options);
export default {
  name: "UsersGroupLists",
  mixins: [Mixin],
  components: {
    BeforeFilter,
    VueJsonToCsv,
  },
  data() {
    return {
      listUrl: '/groups/list',
      json_data: [],
      groupList: [],
      messageColor: "success",
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      statusOptions: [],
      self: this,
      groups: {
        groupId: "",
      },
      columns: ["shortName", 'asUser', "title", "subTitle", 'users', "actions"],
      options: {
        headings: {
          shortName: this.$lang.profile.table.group.shortName,
          asUser: this.$lang.profile.table.group.asUser,
          title: this.$lang.profile.table.group.group,
          subTitle: this.$lang.profile.table.group.subTitle,
          users: this.$lang.profile.table.group.users,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [
          "shortName",
          "title",
        ],
        sortable: ["title"],
        filterable: ["title"],
      },
    };
  },
  mounted() {
    let self = this;
    axios.get('/groups/list/')
        .then((response) => {
          let responseData = response.data;
          responseData.data.map(function (value) {
            self.groupList.push({value: value.groupId, label: value.searchTitle});
          });
        });
    self.groupList = this.removeDuplicateValues(self.groupList);
  },
  methods: {
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    viewUsers(groupId, title) {
      localStorage.setItem('groupTitle', title);
      this.$router.push({path: `/groups/detail/${(groupId)}`})
    },

    applyFilter() {
      let self = this;
      let reqObj = {
        groupId: Object.keys(this.groups.groupList).length > 0 ? this.checkUndefinedNull(self.groups.groupList.value) : null,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      this.groups.groupList = null;
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    }
  },

};
</script>
